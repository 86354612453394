import { RiQuestionLine } from 'react-icons/ri';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { IMarginProps } from '~/shared/interfaces/IMarginProps';

import { Container, TooltipContainer } from './styles';

import { Tooltip } from '../../Tooltip';

interface ILabelProps extends IMarginProps {
  hint?: string;
  onClick?(): void;
}

export const Label: FCWithChildren<ILabelProps> = ({
  hint,
  children,
  onClick,
  ...props
}) => {
  return (
    <Container onClick={onClick} {...props}>
      <span>{children}</span>

      {!!hint && (
        <TooltipContainer>
          <Tooltip label={hint}>
            <div>
              <RiQuestionLine />
            </div>
          </Tooltip>
        </TooltipContainer>
      )}
    </Container>
  );
};
