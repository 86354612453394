import styled from 'styled-components';

import {
  IMarginProps,
  marginPropsStyles,
} from '~/shared/interfaces/IMarginProps';

export const Container = styled.div<IMarginProps>`
  display: flex;
  align-items: center;

  ${marginPropsStyles}

  > span {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-weight: 600;
    display: inline-block;
    font-size: 12px;
    text-align: left;

    letter-spacing: 0.5px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing.xxs};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
