import { CSSProperties, ThemeSpacing } from 'styled-components';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container } from './styles';

interface IFromGroupProps {
  spacing?: ThemeSpacing;
  minWidth?: string;
  style?: CSSProperties;
}

export const FormGroup: FCWithChildren<IFromGroupProps> = ({
  children,
  minWidth = '200px',
  spacing = 'md',
  style,
}) => {
  return (
    <Container
      style={style}
      spacing={spacing}
      minWidth={minWidth}
      id="form-group"
    >
      {children}
    </Container>
  );
};
