import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { IMarginProps } from '~/shared/interfaces/IMarginProps';

import { Text } from './styles';

import { Collapse } from '../../Collapse';

export const ErrorMessage: FCWithChildren<IMarginProps> = ({
  children,
  m,
  mb,
  mh,
  ml,
  mr,
  mt,
  mv,
}) => {
  return (
    <Collapse isOpen={!!children}>
      <Text m={m} mb={mb} mt={mt} ml={ml} mr={mr} mv={mv} mh={mh}>
        {children}
      </Text>
    </Collapse>
  );
};
